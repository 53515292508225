import React, {
  useState,
  forwardRef,
  MutableRefObject,
  useLayoutEffect,
} from "react";
import { ImageObject } from "../classes/ImageObject";
import { isTextObject } from "../util/typeguards";
import { CanvasData } from "../types";

interface CanvasProps
  extends Omit<
    React.HTMLAttributes<HTMLDivElement>,
    "width" | "height" | "ref" | "onMouseMove" | "onMouseUp"
  > {
  bgImg: ImageObject;
  canvasData: MutableRefObject<CanvasData[]>;
  editableObjects: symbol[];
  setEditableObjects: React.Dispatch<React.SetStateAction<symbol[]>>;
  selectedObjects: symbol[];
  setSelectedObjects: React.Dispatch<React.SetStateAction<symbol[]>>;
  selectedCanvases: symbol[];
  setSelectedCanvases: React.Dispatch<React.SetStateAction<symbol[]>>;
  lastClickedObject: symbol | null;
  setLastClickedObject: React.Dispatch<React.SetStateAction<symbol | null>>;
  setHoveredObject: React.Dispatch<React.SetStateAction<symbol | null>>;
  index: number;
  draw: (
    data: CanvasData,
    selectedObjects: symbol[],
    selectedCanvases: symbol[],
  ) => void;
  render: never[];
}

const Canvas = forwardRef<HTMLCanvasElement | null, CanvasProps>(
  (
    {
      bgImg,
      canvasData,
      editableObjects,
      setEditableObjects,
      selectedObjects,
      setSelectedObjects,
      selectedCanvases,
      setSelectedCanvases,
      lastClickedObject,
      setLastClickedObject,
      setHoveredObject,
      index,
      draw,
      render,
      ...props
    }: CanvasProps,
    canvasRef,
  ) => {
    const [dragging, setDragging] = useState(false);

    useLayoutEffect(() => {
      draw(canvasData.current[index], selectedObjects, selectedCanvases);
    }, [render]);

    const getCanvasRef = (): HTMLCanvasElement | null => {
      if (typeof canvasRef === "function") return null;
      return canvasRef?.current ?? null;
    };

    const scale =
      bgImg.state.img.naturalWidth /
      (getCanvasRef()?.getBoundingClientRect().width ?? 1);

    const handleMouseDown = (e: React.MouseEvent<HTMLCanvasElement>) => {
      const canvas = getCanvasRef();
      if (!canvas) return;

      const ctx = canvas.getContext("2d");
      if (!ctx) return;

      const rect = canvas.getBoundingClientRect();
      const clickX = (e.clientX - rect.left) * scale;
      const clickY = (e.clientY - rect.top) * scale;

      const clickedObject = canvasData.current[index].objects.find((obj) =>
        obj.containsPoint(clickX, clickY),
      );

      const selectMultiple = e.ctrlKey || e.shiftKey || e.metaKey;

      if (!clickedObject) {
        setLastClickedObject(null);
      }

      setSelectedObjects((prev) => {
        const newSelectedObjects =
          selectMultiple && clickedObject
            ? [...prev, clickedObject.id]
            : clickedObject
              ? [clickedObject.id]
              : [];

        setSelectedCanvases((prev) => {
          const newSelectedCanvases = clickedObject
            ? []
            : selectMultiple
              ? [...prev, canvasData.current[index].id]
              : [canvasData.current[index].id];

          for (const data of canvasData.current) {
            draw(data, newSelectedObjects, newSelectedCanvases);
          }

          return newSelectedCanvases;
        });
        return newSelectedObjects;
      });
    };

    const handleMouseMove = (e: React.MouseEvent<HTMLCanvasElement>) => {
      const canvas = getCanvasRef();
      if (!canvas) return;

      const rect = canvas.getBoundingClientRect();
      const mouseX = (e.clientX - rect.left) * scale;
      const mouseY = (e.clientY - rect.top) * scale;

      const hoveredObject = canvasData.current[index].objects.find((obj) =>
        obj.containsPoint(mouseX, mouseY),
      );

      if (hoveredObject) {
        setHoveredObject(hoveredObject.id);
      } else {
        setHoveredObject(null);
      }

      if (e.buttons !== 1) return;

      const canvasWidth = canvas.width;
      const middleX = canvasWidth / 2;

      for (const data of canvasData.current) {
        for (const obj of data.objects) {
          const objCenterX = obj.state.x;
          const isNearCenter = Math.abs(objCenterX - middleX) < 20;
          if (
            selectedObjects.includes(obj.id) &&
            (!isTextObject(obj) || !editableObjects.includes(obj.id))
          ) {
            obj.state.x = mouseX;
            obj.state.y = mouseY;
            obj.properties.y = obj.state.y;
            if (!isNearCenter) {
              obj.properties.x = obj.state.x;
            } else {
              obj.properties.x = middleX;
            }
            setDragging(true);
            obj.state.dragging = true;
          }
        }
        draw(data, selectedObjects, selectedCanvases);
      }
    };

    const handleMouseUp = (e: React.MouseEvent<HTMLCanvasElement>) => {
      const canvas = getCanvasRef();
      if (!canvas) return;

      const ctx = canvas.getContext("2d");
      if (!ctx) return;

      const rect = canvas.getBoundingClientRect();
      const clickX = (e.clientX - rect.left) * scale;
      const clickY = (e.clientY - rect.top) * scale;

      const clickedObject = canvasData.current[index].objects.find((obj) =>
        obj.containsPoint(clickX, clickY),
      );

      if (!clickedObject) return;

      if (
        clickedObject.id === lastClickedObject &&
        isTextObject(clickedObject) &&
        !dragging
      ) {
        setEditableObjects((prev) => [...prev, clickedObject.id]);
      }

      setDragging(false);

      for (const data of canvasData.current) {
        for (const obj of data.objects) {
          obj.state.dragging = false;
        }
        draw(data, selectedObjects, selectedCanvases);
      }

      setLastClickedObject(clickedObject.id);
    };

    const textObjects = canvasData.current[index].objects
      .filter((obj) => isTextObject(obj))
      .filter((obj) => editableObjects.includes(obj.id));

    return (
      <div style={{ position: "relative", display: "flex" }} {...props}>
        <canvas
          style={{ width: "300px", height: `${(16 / 9) * 300}px` }}
          ref={canvasRef}
          onMouseDown={handleMouseDown}
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
          width={bgImg.state.img.naturalWidth}
          height={bgImg.state.img.naturalHeight}
        />
        {textObjects.map((textObj, i) => {
          //const ctx = getCanvasRef()?.getContext("2d");
          // if (ctx) {
          //  textObj.updateSize(ctx);
          //}

          return (
            <textarea
              key={i}
              wrap="off"
              rows={1}
              defaultValue={textObj.properties.text}
              onChange={(e) => {
                textObj.properties.text = e.target.value;
                e.currentTarget.style.height = "auto";
                e.currentTarget.style.width = "auto";
                e.currentTarget.style.height = `${e.currentTarget.scrollHeight}px`;
                e.currentTarget.style.width = `${e.currentTarget.scrollWidth}px`;
                draw(
                  canvasData.current[index],
                  selectedObjects,
                  selectedCanvases,
                );
              }}
              style={{
                position: "absolute",
                left: textObj.properties.x * (1 / scale),
                top: textObj.properties.y * (1 / scale),
                transform: "translate(-50%, -50%)",
                fontSize: `${textObj.properties.fontSize * (1 / scale)}px`,
                fontFamily: textObj.properties.fontFamily,
                color: "transparent",
                background: "transparent",
                outline: "none",
                textAlign: "center",
                whiteSpace: "pre",
                caretColor: "black",
                resize: "none",
                overflow: "hidden",
                lineHeight: 1,
                padding: 0,
                border: "none",
              }}
              onFocus={(e) => {
                e.target.setSelectionRange(0, e.target.value.length);
                e.currentTarget.style.height = "auto";
                e.currentTarget.style.width = "auto";
                e.currentTarget.style.height = `${e.currentTarget.scrollHeight}px`;
                e.currentTarget.style.width = `${e.currentTarget.scrollWidth}px`;
              }}
              onBlur={() => {
                setEditableObjects((prev) =>
                  prev.filter((id) => id !== textObj.id),
                );
              }}
              autoFocus
            />
          );
        })}
      </div>
    );
  },
);

Canvas.displayName = "Canvas";

export default Canvas;
