import { CanvasObject } from "./CanvasObject";
import { CanvasState, TextProperties } from "../types";
import WebFont from "webfontloader";

export class TextObject extends CanvasObject {
  type = "TextObject";
  properties: TextProperties;
  state: CanvasState;

  constructor(texts: string[]) {
    super();
    this.properties = {
      texts,
      text: texts[0] ?? "new text",
      x: 0,
      y: 0,
      fontSize: 0,
      fontFamily: "Roboto",
      fillStyle: "white",
      fontWeight: "500",
      strokeStyle: "black",
      lineWidth: 1,
      position: "bottom",
    };
    this.state = {
      x: 0,
      y: 0,
      width: 0,
      height: 0,
      dragging: false,
    };
  }

  loadFont(callback: () => void): void {
    // eslint-disable-next-line
    WebFont.load({
      google: {
        families: [`${this.properties.fontFamily}:300,400,500,600,700`],
      },
      active: () => {
        callback();
      },
      inactive: () => {
        console.error("Font loading failed");
      },
    });
  }

  draw(ctx: CanvasRenderingContext2D): void {
    const canvasWidth = ctx.canvas.width;
    const canvasHeight = ctx.canvas.height;

    if (this.properties.fontSize === 0) {
      this.properties.fontSize = 0.08 * canvasHeight;
    }

    ctx.textAlign = "center";
    ctx.textBaseline = "middle";
    ctx.direction = "inherit";
    ctx.fillStyle = this.properties.fillStyle;
    ctx.font = `${this.properties.fontWeight} ${this.properties.fontSize}px ${this.properties.fontFamily}`;
    ctx.lineWidth = this.properties.lineWidth;
    ctx.strokeStyle = this.properties.strokeStyle;

    if (this.properties.position === "top") {
      this.properties.y = canvasHeight * 0.14; // 10% from top
      this.properties.x = canvasWidth / 2; // Center horizontally
    } else if (this.properties.position === "bottom") {
      this.properties.y = canvasHeight * 0.86; // 10% from bottom
      this.properties.x = canvasWidth / 2; // Center horizontally
    }

    this.properties.position = "";

    const lines = this.properties.text.split("\n");
    const lineHeight = this.properties.fontSize * 1.2;

    lines.forEach((line, index) => {
      const y =
        this.properties.y +
        index * lineHeight -
        ((lines.length - 1) * lineHeight) / 2;
      ctx.fillText(line, this.properties.x, y);
      ctx.strokeText(line, this.properties.x, y);
    });

    this.updateSize(ctx);
  }

  containsPoint(x: number, y: number): boolean {
    return (
      x >= this.properties.x - this.state.width / 2 &&
      x <= this.properties.x + this.state.width / 2 &&
      y >= this.properties.y - this.state.height / 2 &&
      y <= this.properties.y + this.state.height / 2
    );
  }

  updateSize(ctx: CanvasRenderingContext2D): void {
    ctx.font = `${this.properties.fontWeight} ${this.properties.fontSize}px ${this.properties.fontFamily}`;
    ctx.lineWidth = this.properties.lineWidth;
    ctx.strokeStyle = this.properties.strokeStyle;

    const lines = this.properties.text.split("\n");
    const lineHeight = this.properties.fontSize * 1.2;
    this.state.width = Math.max(
      ...lines.map((line) => ctx.measureText(line).width),
    );
    this.state.height = lineHeight * lines.length;
  }
}
