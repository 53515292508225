import { CanvasObject } from "../classes/CanvasObject";
import { TextObject } from "../classes/TextObject";
import textPropertiesConfig from "../configs/textObjectConfig";
import { isTextObject } from "../util/typeguards";
import { TextProperties, CanvasData } from "../types";
import { createRef, MutableRefObject, useRef } from "react";
import { ImageObject } from "../classes/ImageObject";
import "../styles/components/TopBar.css";
import Button from "./Button";
import ColorPicker from "./ColorPicker";
import { post } from "../util/req";
import Dropdown from "./Dropdown";

interface TopBarProps {
  setRender: React.Dispatch<React.SetStateAction<never[]>>;
  canvasData: MutableRefObject<CanvasData[]>;
  selectedObjectIds: symbol[];
  selectedCanvasIds: symbol[];
  draw: (
    data: CanvasData,
    selectedObjects: symbol[],
    selectedCanvases: symbol[],
  ) => void;
}

const TopBar = ({
  setRender,
  canvasData,
  selectedObjectIds,
  selectedCanvasIds,
  draw,
}: TopBarProps) => {
  const folderFileInputRef = useRef<HTMLInputElement>(null);
  const singleFileInputRef = useRef<HTMLInputElement>(null);
  const textFileInputRef = useRef<HTMLInputElement>(null);
  const apiUrl = process.env.REACT_APP_API_URL ?? "";

  const selectedObjects = canvasData.current
    .map((data) => data.objects)
    .flat()
    .filter((obj) => selectedObjectIds.includes(obj.id));

  const showCanvasOptions = canvasData.current.find((data) =>
    selectedCanvasIds.includes(data.id),
  );
  const showRandomizeImage = canvasData.current.find(
    (data) => data.bgImg.properties.sources.length > 1,
  );
  const showRandomizeText = selectedObjects.some(
    (obj) => isTextObject(obj) && obj.properties.texts.length > 1,
  );

  const uploadToTikTok = async () => {
    const formData = new FormData();
    const promises = canvasData.current.map((data, index) => {
      return new Promise<void>((resolve) => {
        const canvas = data.ref.current;
        if (!canvas) return;
        canvas.toBlob((blob) => {
          if (!blob) return;
          formData.append(`images`, blob, `${index + 1}.jpeg`);
          resolve();
        }, "image/jpeg");
      });
    });

    await Promise.all(promises);

    post(
      `${apiUrl}/upload/slideshow`,
      { Authorization: localStorage.getItem("token") },
      formData,
    ).catch((error: unknown) => {
      console.error(error);
    });
  };

  const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = Array.from(event.target.files ?? []);
    const imageUrls = files.map((file) => URL.createObjectURL(file));

    const newCanvasData = {
      id: Symbol(canvasData.current.length),
      ref: createRef<HTMLCanvasElement>(),
      objects: [],
      bgImg: new ImageObject(imageUrls, { x: 0, y: 0 }, "auto", "auto"),
      selected: false,
    };

    canvasData.current = [...canvasData.current, newCanvasData];
    newCanvasData.bgImg.state.img.onload = () => {
      setRender([]);
    };
    newCanvasData.bgImg.state.img.src = newCanvasData.bgImg.properties.source;
    event.target.value = "";
  };

  const handleAddSlide = (option: string) => {
    if (option === "Folder") {
      folderFileInputRef.current?.click();
    } else {
      singleFileInputRef.current?.click();
    }
  };

  const handleChange = (property: string, value: string) => {
    for (const data of canvasData.current) {
      for (const obj of data.objects) {
        if (selectedObjectIds.includes(obj.id) && property in obj.properties) {
          // @ts-expect-error Can't index properties with a string
          obj.properties[property] = value;
          if (property === "fontFamily" && isTextObject(obj)) {
            obj.loadFont(() => {
              draw(data, selectedObjectIds, selectedCanvasIds);
            });
          }
        }
      }
      draw(data, selectedObjectIds, selectedCanvasIds);
    }
  };

  const getCommonValue = (
    property: string,
    typeguard: (obj: CanvasObject) => obj is TextObject,
  ) => {
    if (selectedObjects.length === 0) return "";
    const specificSelectedObjects = selectedObjects.filter(typeguard);
    const uniqueProperties = new Set<string | string[] | number>();
    specificSelectedObjects.forEach((obj) =>
      uniqueProperties.add(obj.properties[property as keyof TextProperties]),
    );
    return uniqueProperties.size === 1 ? [...uniqueProperties][0] : "";
  };

  const handleAddSingleText = (texts: string[]) => {
    for (const data of canvasData.current) {
      if (selectedCanvasIds.includes(data.id)) {
        const newTextObj = new TextObject([...texts]);
        data.objects.push(newTextObj);
        newTextObj.loadFont(() => {
          setRender([]);
        });
      }
    }
  };

  const handleAddTextFromFile = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (!event.target.files) {
      return;
    }
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      const text = e.target?.result;
      if (text && typeof text === "string") {
        handleAddSingleText(text.split(/\r?\n/));
      }
    };
    reader.readAsText(file);
  };

  const handleAddText = (option: string) => {
    if (option === "File") {
      textFileInputRef.current?.click();
    } else {
      handleAddSingleText(["new text"]);
    }
  };

  const handleRandomizeImage = () => {
    for (const data of canvasData.current) {
      if (selectedCanvasIds.includes(data.id)) {
        data.bgImg.properties.source =
          data.bgImg.properties.sources[
            Math.floor(Math.random() * data.bgImg.properties.sources.length)
          ];
        data.bgImg.state.img = new Image();
        data.bgImg.state.img.onload = () => {
          setRender([]);
        };
        data.bgImg.state.img.src = data.bgImg.properties.source;
      }
    }
  };

  const handleRandomizeText = () => {
    for (const data of canvasData.current) {
      for (const obj of data.objects) {
        if (selectedObjectIds.includes(obj.id) && isTextObject(obj)) {
          obj.properties.text =
            obj.properties.texts[
              Math.floor(Math.random() * obj.properties.texts.length)
            ];
        }
      }
      draw(data, selectedObjectIds, selectedCanvasIds);
    }
  };

  const handleMakeUnique = () => {
    for (const data of canvasData.current) {
      if (selectedCanvasIds.includes(data.id)) {
        const canvas = data.ref.current;
        if (!canvas) return;
        const ctx = canvas.getContext("2d");
        if (!ctx) return;
        const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
        const randomX = Math.floor(Math.random() * canvas.width);
        const randomY = Math.floor(Math.random() * canvas.height);
        const index = (randomY * canvas.width + randomX) * 4;
        imageData.data[index] = 0;
        imageData.data[index + 1] = 0;
        imageData.data[index + 2] = 0;
        imageData.data[index + 3] = 255;
        ctx.putImageData(imageData, 0, 0);
      }
      draw(data, selectedObjectIds, selectedCanvasIds);
    }
  };

  return (
    <div style={styles.topBar} id="top-bar">
      {showCanvasOptions && (
        <>
          <Dropdown
            options={["File", "Single"]}
            onSelect={handleAddText}
            defaultValue="Add Text"
          />
          <input
            style={{ display: "none" }}
            ref={textFileInputRef}
            type="file"
            onChange={handleAddTextFromFile}
          />
          <Button onClick={handleMakeUnique}>Make unique</Button>
          {showRandomizeImage && (
            <Button onClick={handleRandomizeImage}>Randomize</Button>
          )}
        </>
      )}

      {selectedObjects.length === 0 && !showCanvasOptions && (
        <>
          <Dropdown
            options={["Folder", "Single"]}
            onSelect={handleAddSlide}
            defaultValue="Add Slide"
          />
          <input
            ref={folderFileInputRef}
            type="file"
            accept="image/*"
            style={{ display: "none" }}
            multiple
            // @ts-expect-error Webkitdirectory is not recognized by TypeScript
            webkitdirectory="true"
            onChange={handleFileSelect}
          />
          <input
            ref={singleFileInputRef}
            type="file"
            accept="image/*"
            style={{ display: "none" }}
            onChange={handleFileSelect}
          />
          <Button onClick={uploadToTikTok}>Upload</Button>
        </>
      )}
      {selectedObjects.length > 0 && (
        <>
          {Object.keys(textPropertiesConfig).map((property) => {
            const config =
              textPropertiesConfig[
                property as keyof typeof textPropertiesConfig
              ];
            const commonValue = getCommonValue(property, isTextObject);

            return (
              <div key={property} style={styles.inputContainer}>
                <label>{config.label}</label>
                {config.type === "select" && config.options ? (
                  <select
                    key={String(commonValue)}
                    defaultValue={commonValue}
                    onChange={(e) => {
                      handleChange(property, e.target.value);
                    }}
                  >
                    {config.options.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                ) : config.type === "color" ? (
                  <ColorPicker
                    key={String(commonValue)}
                    onChange={(color) => {
                      handleChange(property, color);
                    }}
                  />
                ) : (
                  <input
                    key={String(commonValue)}
                    type={config.type}
                    min={config.min}
                    max={config.max}
                    step={config.step}
                    defaultValue={commonValue}
                    onChange={(e) => {
                      handleChange(property, e.target.value);
                    }}
                  />
                )}
              </div>
            );
          })}
          {showRandomizeText && (
            <Button onClick={handleRandomizeText}>Randomize</Button>
          )}
        </>
      )}
    </div>
  );
};

const styles: Record<string, React.CSSProperties> = {
  topBar: {
    position: "sticky",
    top: 0,
    left: 0,
    width: "100%",
    backgroundColor: "#292c31",
    color: "#fff",
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "center",
    gap: "1rem",
    padding: "15px 0",
    minHeight: "61px",
    boxSizing: "border-box",
    zIndex: 1000,
  },
  inputContainer: {
    display: "flex",
    flexDirection: "row",
    gap: "0.5rem",
    alignItems: "center",
  },
};

export default TopBar;
