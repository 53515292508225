import { CanvasState, ImageProperties, TextProperties } from "../types";

export abstract class CanvasObject {
  id = Symbol();
  abstract type: string;
  abstract properties: TextProperties | ImageProperties;
  abstract state: CanvasState;

  constructor() {
    this.id = Symbol();
  }

  abstract draw(ctx: CanvasRenderingContext2D): void;

  abstract containsPoint(x: number, y: number): boolean;
}
