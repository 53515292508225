import { TextProperties } from "../types";
import { PropertyConfig } from "../types";

type TextPropertiesConfig = {
  [K in keyof TextProperties | "position"]: PropertyConfig;
};

const textPropertiesConfig: Omit<
  TextPropertiesConfig,
  "x" | "y" | "text" | "texts"
> = {
  fontFamily: {
    label: "Font Family",
    type: "select",
    options: [
      "Roboto",
      "Open Sans",
      "Lato",
      "Montserrat",
      "Poppins",
      "Raleway",
      "Oswald",
      "Merriweather",
      "Nunito",
      "Playfair Display",
      "Rubik",
      "Source Sans Pro",
      "PT Sans",
      "Noto Sans",
      "Work Sans",
      "Ubuntu",
      "Fira Sans",
      "Cabin",
      "Quicksand",
      "Josefin Sans",
    ],
  },
  fontWeight: {
    label: "Font Weight",
    type: "select",
    options: ["300", "400", "500", "600", "700"],
  },
  position: {
    label: "Position",
    type: "select",
    options: ["top", "bottom"],
  },
  fontSize: {
    label: "Font Size",
    type: "number",
    min: 1,
    max: 100,
    step: 1,
  },
  lineWidth: {
    label: "Stroke Width",
    type: "number",
    min: 0,
    max: 10,
    step: 0.1,
  },
  fillStyle: {
    label: "Fill",
    type: "color",
  },
  strokeStyle: {
    label: "Stroke",
    type: "color",
  },
};

export default textPropertiesConfig;
