import React, { ReactNode } from "react";
import "../styles/components/Button.css"; // Import the CSS file for hover styles

interface ButtonProps {
  type?: "plain";
  color?: "white";
  onClick?: () => void;
  children: ReactNode;
}

const Button: React.FC<ButtonProps> = ({
  type = "plain",
  color = "white", // Light color on dark background
  onClick = () => null,
  children,
}) => {
  return (
    <button className={`button-${type} button-${color}`} onClick={onClick}>
      {children}
    </button>
  );
};

export default Button;
