import React, { useState } from "react";
import "../styles/components/ColorPicker.css";

interface ColorPickerProps {
  onChange: (color: string) => void; // Add onChange prop
}

const ColorPicker = ({ onChange }: ColorPickerProps) => {
  const [selectedColor, setSelectedColor] = useState("#000000"); // Default color

  const handleColorChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedColor(event.target.value);
    onChange(event.target.value);
  };

  return (
    <div className="color-picker-wrapper">
      <input
        type="color"
        value={selectedColor}
        onChange={handleColorChange}
        className="native-color-picker"
      />
      <div
        className="custom-color-box"
        style={{ backgroundColor: selectedColor }}
      ></div>
    </div>
  );
};

export default ColorPicker;
