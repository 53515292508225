const get = async <T>(url: string, headers = {}): Promise<T> => {
  const response = await fetch(url, {
    method: "GET",
    headers: headers,
  });

  if (!response.ok) {
    throw new Error(
      `GET request failed: ${response.status} ${response.statusText}`,
    );
  }

  const contentType = response.headers.get("Content-Type");

  let data: T;
  if (contentType?.includes("application/json")) {
    data = (await response.json()) as T;
  } else {
    data = (await response.text()) as T;
  }

  return data;
};

const post = async <T>(url: string, headers = {}, body = {}): Promise<T> => {
  const response = await fetch(url, {
    method: "POST",
    headers: {
      ...headers,
    },
    body: body instanceof FormData ? body : JSON.stringify(body),
  });

  if (!response.ok) {
    throw new Error(
      `POST request failed: ${response.status} ${response.statusText}`,
    );
  }

  const contentType = response.headers.get("Content-Type");

  let data: T;
  if (contentType?.includes("application/json")) {
    data = (await response.json()) as T;
  } else {
    data = (await response.text()) as T;
  }

  return data;
};

export { get, post };
