import "./styles/app.css";
import { useEffect, useRef, useState } from "react";
import { post } from "./util/req";
import Canvas from "./components/Canvas";
import TopBar from "./components/TopBar";
import { CanvasData } from "./types";
import useDraw from "./hooks/useDraw";

function App() {
  const apiUrl = process.env.REACT_APP_API_URL ?? "";
  const canvasData = useRef<CanvasData[]>([]);
  const [render, setRender] = useState([]);
  const [editableObjects, setEditableObjects] = useState<symbol[]>([]);
  const [selectedObjects, setSelectedObjects] = useState<symbol[]>([]);
  const [selectedCanvases, setSelectedCanvases] = useState<symbol[]>([]);
  const [lastClickedObject, setLastClickedObject] = useState<symbol | null>(
    null,
  );
  const [lastActiveElement, setLastActiveElement] =
    useState<HTMLElement | null>(null);

  const [draggedCanvas, setDraggedCanvas] = useState<symbol | null>(null);
  const [dragOffset, setDragOffset] = useState<number>(0);
  const [positions, setPositions] = useState<number[]>([]);
  const [transforms, setTransforms] = useState<Record<symbol, number>>({});
  const [transitions, setTransitions] = useState<Record<symbol, boolean>>({});
  const [hoveredObject, setHoveredObject] = useState<symbol | null>(null);

  const handleMouseDown = (event: React.MouseEvent, data: CanvasData) => {
    if (hoveredObject) return;

    const draggedElement = data.ref.current;

    if (!draggedElement) return;

    setDraggedCanvas(data.id); // Set dragged canvas
    setDragOffset(event.clientX - draggedElement.getBoundingClientRect().left); // Set initial mouse offset
    setPositions(
      canvasData.current.map(
        (data) => data.ref.current?.getBoundingClientRect().left ?? 0,
      ),
    );
  };

  const handleMouseUp = () => {
    setDraggedCanvas(null);
    setDragOffset(0);
    setTransforms({});
    setTransitions({});
  };

  const handleMouseMove = (event: React.MouseEvent<HTMLDivElement>) => {
    if (draggedCanvas === null) return;

    const draggedCanvasIndex = canvasData.current.findIndex(
      (data) => data.id === draggedCanvas,
    );
    const deltaX = event.clientX - dragOffset - positions[draggedCanvasIndex];
    const draggedElement =
      canvasData.current[draggedCanvasIndex].ref.current?.parentElement;
    if (!draggedElement) return;

    setTransforms((prev) => ({
      ...prev,
      [draggedCanvas]: deltaX,
    }));

    const draggedRect = draggedElement.getBoundingClientRect();

    canvasData.current.forEach((data, i) => {
      if (data.id !== draggedCanvas) {
        const otherCanvas = data.ref.current;
        if (!otherCanvas) return;

        const otherRect = otherCanvas.getBoundingClientRect();

        if (
          ((draggedRect.right > otherRect.left + otherRect.width / 2 + 10 &&
            draggedCanvasIndex < i) ||
            (draggedRect.left < otherRect.right - otherRect.width / 2 - 10 &&
              draggedCanvasIndex > i)) &&
          otherRect.left === positions[i]
        ) {
          canvasData.current = [...canvasData.current]; // Copy the array to trigger re-render
          const temp = canvasData.current[draggedCanvasIndex];
          canvasData.current[draggedCanvasIndex] = canvasData.current[i];
          canvasData.current[i] = temp;
          setTransforms((prevTransforms) => ({
            ...prevTransforms,
            [draggedCanvas]: event.clientX - dragOffset - positions[i],
            [data.id]: positions[i] - positions[draggedCanvasIndex],
          }));
          setTransitions((prev) => ({
            ...prev,
            [data.id]: false, // Disable transition initially
          }));
          requestAnimationFrame(() => {
            requestAnimationFrame(() => {
              setTransitions((prev) => ({
                ...prev,
                [data.id]: true, // Enable transition
              }));
              setTransforms((prevTransforms) => ({
                ...prevTransforms,
                [data.id]: 0, // Set translateX(0) to trigger the animation
              }));
            });
          });
          return;
        }
      }
    });
  };

  const draw = useDraw();

  const queryParameters = new URLSearchParams(window.location.search);
  const code = queryParameters.get("code");

  useEffect(() => {
    const fetchToken = async () => {
      const data = await post<{ access_token: string; error: string }>(
        `${apiUrl}/login/token`,
        { "Content-Type": "application/json" },
        { code },
      );
      if (!data.error) {
        localStorage.setItem("token", data.access_token);
      }
    };

    if (code) {
      fetchToken().catch((error: unknown) => {
        console.error(error);
      });
    }
  }, [apiUrl, code]);

  const handleOutsideClick = (event: React.MouseEvent<HTMLDivElement>) => {
    const clickedItem = event.target;
    if (!(clickedItem instanceof HTMLElement)) {
      return;
    }

    const topBar = document.getElementById("top-bar");

    const isInputOrSelect =
      event.target instanceof HTMLInputElement ||
      event.target instanceof HTMLSelectElement ||
      event.target instanceof HTMLTextAreaElement ||
      lastActiveElement instanceof HTMLTextAreaElement ||
      lastActiveElement instanceof HTMLInputElement ||
      lastActiveElement instanceof HTMLSelectElement;

    console.log(clickedItem);

    if (isInputOrSelect) {
      setLastActiveElement(clickedItem);
      return;
    }

    if (topBar?.contains(clickedItem)) {
      console.log("clicked top bar");
      return;
    }

    const isClickOutside = canvasData.current.every(
      (data) => data.ref.current && !data.ref.current.contains(clickedItem),
    );

    if (isClickOutside) {
      const newSelectedObjects: symbol[] = [];
      const newSelectedCanvases: symbol[] = [];
      setSelectedObjects(newSelectedObjects);
      setSelectedCanvases(newSelectedCanvases);
      setLastClickedObject(null);
      for (const data of canvasData.current) {
        draw(data, newSelectedObjects, newSelectedCanvases);
      }
      setLastActiveElement(null);
    }
  };

  return (
    <div
      role="presentation"
      onMouseDown={handleOutsideClick}
      style={{
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        overflowX: "hidden",
      }}
    >
      <TopBar
        setRender={setRender}
        canvasData={canvasData}
        selectedObjectIds={selectedObjects}
        selectedCanvasIds={selectedCanvases}
        draw={draw}
      />
      <div
        role="presentation"
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          flexGrow: 1,
          gap: "2rem",
          flexWrap: "wrap",
          padding: "2rem",
          backgroundColor: "#111111",
        }}
      >
        {canvasData.current.map((data, index) => (
          <Canvas
            key={data.id.toString()}
            className="slide"
            onMouseDown={(event) => {
              handleMouseDown(event, data);
            }}
            canvasData={canvasData}
            editableObjects={editableObjects}
            setEditableObjects={setEditableObjects}
            selectedObjects={selectedObjects}
            setSelectedObjects={setSelectedObjects}
            selectedCanvases={selectedCanvases}
            setSelectedCanvases={setSelectedCanvases}
            lastClickedObject={lastClickedObject}
            setLastClickedObject={setLastClickedObject}
            setHoveredObject={setHoveredObject}
            index={index}
            draw={draw}
            render={render}
            ref={canvasData.current[index].ref}
            bgImg={canvasData.current[index].bgImg}
            style={{
              transform: `translateX(${transforms[data.id] || 0}px)`,
              transition: transitions[data.id] ? "transform 0.3s ease" : "none",
              opacity: draggedCanvas === data.id ? 0.7 : 1,
              zIndex: draggedCanvas === data.id ? 1 : 0,
              cursor:
                hoveredObject && selectedObjects.includes(hoveredObject)
                  ? "move"
                  : hoveredObject
                    ? "pointer"
                    : "move",
            }}
          />
        ))}
      </div>
    </div>
  );
}

export default App;
