import ReactDOM from "react-dom/client";
import "./styles/index.css";
import App from "./App";

const rootElement = document.getElementById("root");

if (rootElement) {
  const root = ReactDOM.createRoot(rootElement);
  root.render(<App />);
} else {
  console.error(
    "Root element not found. Make sure there is an element with id 'root' in your HTML.",
  );
}
