import { useRef } from "react";
import { CanvasData } from "../types";

const useDraw = () => {
  const animationFrameRef = useRef<number | null>(null);

  const draw = (
    data: CanvasData,
    selectedObjects: symbol[],
    selectedCanvases: symbol[],
  ) => {
    if (animationFrameRef.current) {
      cancelAnimationFrame(animationFrameRef.current);
    }

    requestAnimationFrame(() => {
      const canvas = data.ref.current;
      if (!canvas) return;

      const ctx = canvas.getContext("2d");
      if (!ctx) return;

      ctx.clearRect(0, 0, canvas.width, canvas.height);

      ctx.fillStyle = "black";
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      data.bgImg.draw(ctx);

      if (selectedCanvases.includes(data.id)) {
        ctx.strokeStyle = "#39FF14";
        ctx.lineWidth = 3;
        ctx.strokeRect(0, 0, canvas.width, canvas.height);
      }

      data.objects.forEach((obj) => {
        obj.draw(ctx);

        if (selectedObjects.includes(obj.id)) {
          ctx.strokeStyle = "#39FF14";
          ctx.lineWidth = 2;
          ctx.strokeRect(
            obj.properties.x - obj.state.width / 2,
            obj.properties.y - obj.state.height / 2,
            obj.state.width,
            obj.state.height,
          );
        }
      });

      const canvasWidth = canvas.width;
      const middleX = canvasWidth / 2;

      const isCentered = data.objects.some((obj) => {
        const objCenterX = obj.properties.x;
        return Math.abs(objCenterX - middleX) < 5 && obj.state.dragging;
      });

      if (isCentered) {
        ctx.strokeStyle = "#39FF14";
        ctx.setLineDash([5, 5]);
        const middleX = canvas.width / 2;
        ctx.beginPath();
        ctx.moveTo(middleX, 0);
        ctx.lineTo(middleX, canvas.height);
        ctx.stroke();
        ctx.setLineDash([]);
      }
    });
  };

  return draw;
};

export default useDraw;
